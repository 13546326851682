import { Tooltip, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { BulkResponse } from '@edgeiq/edgeiq-api-js';
import { TableColumn, TableItemType } from '../../../components/SharedTable';
import timeHelpers from '../../../helpers/timeHelpers';
import { formatReadableActionName } from '../../../helpers/utils';

export const BulkJobsColumns = (
  handleActionClick: (item: BulkResponse, action: 'details') => void,
): TableColumn[] => {
  return [
    {
      cellValue: (item: TableItemType): string => {
        const bulkResponse = item as BulkResponse;
        return `${timeHelpers.getDate(bulkResponse.created_at)}`;
      },
      id: 'created_at',
      label: 'Date',
      type: 'text',
    },
    {
      id: 'status',
      label: 'Status',
      parser: (item: TableItemType): string | React.ReactElement => {
        const bulkResponse = item as BulkResponse;
        const status = bulkResponse.bulk_status;
        let icon: React.ReactElement = <></>;
        let text: string = '';
        if (status === 'created') {
          icon = <NewReleasesIcon color="info" />;
          text = 'Created successfully';
        } else if (status === 'started') {
          icon = <AutorenewIcon color="info" />;
          text = 'Processing';
        } else if (status === 'aborted') {
          icon = <ErrorIcon color="error" />;
          text = 'Aborted';
        } else if (status === 'finished') {
          if (bulkResponse.successes === bulkResponse.total) {
            icon = <CheckCircleIcon color="success" />;
            text = 'Finished. All items processed successfully';
          } else if (bulkResponse.failures) {
            icon = <WarningIcon color="error" />;
            text = 'Finished with failures';
          } else {
            icon = <WarningIcon color="warning" />;
            text = `Finished. Some items didn't process successfully`;
          }
        }
        if (icon && text) {
          return (
            <Tooltip title={text} placement="bottom-start">
              {icon}
            </Tooltip>
          );
        }
        return '--';
      },
      type: 'custom',
    },
    {
      cellValue: (item: TableItemType): string => {
        const bulkResponse = item as BulkResponse;
        return `${bulkResponse.successes}`;
      },
      id: 'successes',
      label: 'Successes',
      type: 'text',
    },
    {
      cellValue: (item: TableItemType): string => {
        const bulkResponse = item as BulkResponse;
        return `${bulkResponse.failures}`;
      },
      id: 'failues',
      label: 'Failures',
      type: 'text',
    },
    {
      cellValue: (item: TableItemType): string => {
        const bulkResponse = item as BulkResponse;
        return formatReadableActionName(bulkResponse.type);
      },
      id: 'type',
      label: 'Type',
      type: 'text',
    },
    {
      id: 'details',
      label: 'Details',
      parser: (item: TableItemType): React.ReactElement => {
        const bulkResponse = item as BulkResponse;
        return (
          <Typography
            variant="button"
            style={{ cursor: 'pointer' }}
            onClick={(): void => handleActionClick(bulkResponse, 'details')}
          >
            View Details
          </Typography>
        );
      },
      type: 'custom',
    },
  ];
};

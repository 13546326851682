import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import {
  BulkResponse,
  BulkResponses,
  BulkResponseStatus,
  Command,
  Commands,
  PaginationFilter,
} from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch } from '../../../redux/hooks';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import RightDrawer from '../../../components/RightDrawer/RightDrawer';
import { formatReadableActionName } from '../../../helpers/utils';
import timeHelpers from '../../../helpers/timeHelpers';
import UserAvatar from '../../../components/UserAvatar';
import { useUserWithType } from '../../../hooks/useUserWithType';
import { defaultItemsPerPage, errorHighlight } from '../../../app/constants';
import useStyles from './styles';
import { gatewayActions } from '../IssueCommand/constants';

const bulkCommandTypes = [
  'command',
  'gateway_command',
  ...gatewayActions.map((action) => action.action),
];

interface BulkJobInformationDrawerProps {
  open: boolean;
  handleCloseDrawer: () => void;
  bulkResponse: BulkResponse;
}

const BulkJobInformationDrawer: React.FC<BulkJobInformationDrawerProps> = ({
  open,
  handleCloseDrawer,
  bulkResponse,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const { user } = useUserWithType(bulkResponse.user_id, false);
  const [statuses, setStatuses] = useState<BulkResponseStatus[]>([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingCommand, setLoadingCommand] = useState(true);
  const [command, setCommand] = useState<Command>();

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const getStatuses = (pageNumber: number, addPage = false): void => {
    const pagination: PaginationFilter = {
      itemsPerPage: defaultItemsPerPage,
      order_by: 'status',
      page: pageNumber,
    };

    BulkResponses.listBulkResponseStatuses(
      {
        bulk_response_id: {
          operator: 'eq',
          value: bulkResponse._id,
        },
      },
      pagination,
    )
      .then((result) => {
        const results: BulkResponseStatus[] = addPage
          ? [...statuses, ...result.statuses]
          : result.statuses;
        setStatuses(results);
        setTotal(result.pagination.total);
        if (addPage) {
          setPage(page + 1);
        }
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getStatuses(1);

    if (bulkResponse.type === 'command' && bulkResponse.command_id) {
      Commands.getOneById(bulkResponse.command_id)
        .then((result) => {
          setCommand(result);
        })
        .catch((error) => {
          dispatchError(error.message);
        })
        .finally(() => {
          setLoadingCommand(false);
        });
    }
  }, [bulkResponse]);

  const handleLoadMore = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setLoading(true);
    getStatuses(page + 1, true);
  };

  const goToCommand = (): void => {
    navigate(`/command/${bulkResponse.command_id}`);
  };

  const render_summary = (): JSX.Element => {
    let title = 'Pending';
    let icon: JSX.Element = <></>;
    if (bulkResponse.total != null) {
      const failures = bulkResponse.failures;
      const successes = bulkResponse.successes;
      console.info(bulkResponse);
      if (failures + successes === bulkResponse.total) {
        if (failures === 0) {
          title = 'Completed with No Failures';
          icon = <CheckCircleIcon color="success" />;
        } else if (failures > 0 && successes > 0) {
          title = 'Completed with Some Failures';
          icon = <WarningIcon color="warning" />;
        } else if (failures > 0 && successes === 0) {
          title = 'Completed with No Successes';
          icon = <ErrorIcon color="error" />;
        }
      }
    }
    return (
      <div className="d-flex flex-items-center">
        {icon}
        <span style={{ marginLeft: '6px' }}>{title}</span>
      </div>
    );
  };

  const renderStatus = (status: BulkResponseStatus): JSX.Element => {
    const isFail = status.status === 'fail';
    const icon: JSX.Element = isFail ? (
      <ErrorIcon color="error" className="mr-2" />
    ) : (
      <CheckCircleIcon color="success" className="mr-2" />
    );

    const failMessage = 'An error occurred';
    const type = bulkResponse.type;
    if (type === 'create_devices' || type === 'create_escrow_devices') {
      return (
        <>
          {icon}
          {isFail ? (
            <span>
              {`${failMessage} creating device: `}
              {status.error}
            </span>
          ) : (
            <span>{`Device created correctly. ID: ${status.device_id}`}</span>
          )}
        </>
      );
    } else if (type === 'update_devices') {
      return (
        <>
          {icon}
          {isFail ? (
            <Typography variant="button">
              {`${failMessage} creating device: `}
              {status.error}
            </Typography>
          ) : (
            <Typography variant="button">
              {`Device created correctly. ID: ${status.device_id}`}
            </Typography>
          )}
        </>
      );
    } else if (type === 'csv_upload') {
      return (
        <>
          {icon}
          {isFail ? (
            <Typography variant="button">
              {`${failMessage} ${
                status.device_id
                  ? `updating device ID "${status.device_id}"`
                  : 'creating device'
              }: ${status.error}`}
            </Typography>
          ) : (
            <Typography variant="button">
              {`Device created/updated correctly. ID: ${status.device_id}`}
            </Typography>
          )}
        </>
      );
    } else if (type === 'device_transfer_request') {
      return (
        <>
          {icon}
          {isFail ? (
            <Typography variant="button">
              {`${failMessage} transferring device ID "${status.device_id}": ${status.error}`}
            </Typography>
          ) : (
            <Typography variant="button">
              {`Device transferred correctly. ID: ${status.device_id}`}
            </Typography>
          )}
        </>
      );
    } else if (bulkCommandTypes.includes(type)) {
      return (
        <>
          {icon}
          {isFail ? (
            <Typography variant="button">
              {`${failMessage} executing command on device ID "${status.device_id}": ${status.error}`}
            </Typography>
          ) : (
            <Typography variant="button">
              {`Command executed correctly on device ID: ${status.device_id}`}
            </Typography>
          )}
        </>
      );
    } else if (type === 'bulk_promote_discovered_devices') {
      return (
        <>
          {icon}
          {isFail ? (
            <Typography variant="button">
              {`${failMessage} promoting device ID "${status.device_id}": ${status.error}`}
            </Typography>
          ) : (
            <Typography variant="button">
              {`Device promoted correctly. ID: ${status.device_id}`}
            </Typography>
          )}
        </>
      );
    } else if (bulkResponse.type.indexOf('delete') !== -1) {
      return (
        <>
          {icon}
          {isFail ? (
            <Typography variant="button">
              {`${failMessage} deleting item ID "${status.device_id}": ${status.error}`}
            </Typography>
          ) : (
            <Typography variant="button">
              {`Item deleted correctly. Deleted ID: ${status.device_id}`}
            </Typography>
          )}
        </>
      );
    }
    return <></>;
  };

  return (
    <RightDrawer
      open={open}
      actionLabel="Done"
      title="Bulk Job Information"
      disableAction={false}
      actionCallback={handleCloseDrawer}
      onCloseDrawer={handleCloseDrawer}
      content={
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" className="custom-label">
              Created At
            </Typography>
            <Typography variant="subtitle2" className="custom-label">
              {timeHelpers.getDate(bulkResponse.created_at)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" className="custom-label">
              Created By
            </Typography>
            <UserAvatar
              name={user ? `${user?.first_name} ${user?.last_name}` : ''}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" className="custom-label">
              Action
            </Typography>

            <Typography variant="subtitle2" className="custom-label">
              {formatReadableActionName(bulkResponse.type)}
            </Typography>
          </Grid>

          {bulkResponse.type === 'command' && (
            <Grid item xs={12} md={6}>
              <Typography variant="h6" className="custom-label">
                Command
              </Typography>

              {!loadingCommand && (
                <Typography
                  variant="subtitle2"
                  className={clsx('custom-label', classes.linkButton)}
                  onClick={goToCommand}
                >
                  {command ? command.name : bulkResponse.command_id}
                </Typography>
              )}
            </Grid>
          )}

          <Grid item xs={12} md={6}>
            <Typography variant="h6" className="custom-label">
              Status
            </Typography>

            <Typography variant="subtitle2" className="custom-label">
              {render_summary()}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" className="custom-label">
              Status Details:{' '}
              {`Total: ${bulkResponse.total}. Failures: ${bulkResponse.failures}. Successes: ${bulkResponse.successes}.`}
            </Typography>
            <div>
              {statuses.map((status, index) => (
                <div key={`${status.device_id}_${index}`}>
                  <div className={`d-flex mt-2`}>{renderStatus(status)}</div>
                </div>
              ))}
            </div>
            {statuses.length !== total && (
              <div className="d-flex flex-justify-center">
                <Button
                  variant="outlined"
                  size="large"
                  onClick={handleLoadMore}
                >
                  {!loading ? (
                    <Typography variant="button">Load more</Typography>
                  ) : (
                    <CircularProgress size={25} />
                  )}
                </Button>
              </div>
            )}
          </Grid>
        </Grid>
      }
    />
  );
};

export default BulkJobInformationDrawer;
